<template>
  <div class="content-general-information">
    <div class="content-img">
      <v-skeleton-loader
        class="img-raw-material mb-5"
        type="image"
      ></v-skeleton-loader>
    </div>
    <v-row class="content-information">
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <p class="p-title">
          <v-skeleton-loader type="text" width="auto"></v-skeleton-loader>
        </p>
        <p class="p-text">
          <v-skeleton-loader type="text" width="auto"></v-skeleton-loader>
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <p class="p-title">
          <v-skeleton-loader type="text" width="auto"></v-skeleton-loader>
        </p>
        <p class="p-text">
          <v-skeleton-loader type="text" width="auto"></v-skeleton-loader>
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <p class="p-title">
          <v-skeleton-loader type="text" width="auto"></v-skeleton-loader>
        </p>
        <p class="p-text">
          <v-skeleton-loader type="text" width="auto"></v-skeleton-loader>
        </p>
      </v-col>
    </v-row>
    <div class="content-btn ml-2">
      <v-skeleton-loader type="button" width="auto"></v-skeleton-loader>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
.content-general-information {
  display: flex;
  width: 100%;
}

.p-text-title {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  letter-spacing: 4.8px;
  opacity: 1;
}

.content-information {
  align-items: center;
  display: flex;
}

.p-title {
  color: var(--primary-color-text);
  margin-bottom: 0px;
  font-family: "pop-Regular";
  font-size: 14px;
}
.p-text {
  color: var(--primary-color-text);
  margin-bottom: 0px;
  margin-left: 10px;
  font-family: "pop-Regular";
  font-size: 14px;
}
.content-img {
  display: flex;
  justify-content: center;
  margin-right: 10px;
}
.img-raw-material {
  height: 100px;
  width: 100px;
  background-size: cover;
  border-radius: 20px;
}

.content-btn {
  width: 170px;
  align-self: center;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-general-information {
    display: block;
    width: 100%;
  }
  .content-img {
    display: block;
    justify-content: center;
    text-align: -webkit-center;
    margin-right: 0px;
  }

  .content-information {
    margin-bottom: 10px;
  }
  .content-btn {
    width: 100%;
    text-align: -webkit-center;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>